//FETCH RESTAURANT
export const FETCH_RESTAURANT_LIST_SUCCESS = "FETCH_RESTAURANT_LIST_SUCCESS";
export const FETCH_RESTAURANT_LIST_ERROR = "FETCH_RESTAURANT_LIST_ERROR";

//PAGE_CHANGE Actions
export const RESTAURANT_MANAGEMENT_PAGE_CHANGE = "HOTEL_MANAGEMENT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const RESTAURANT_MANAGEMENT_LIMIT_CHANGE = "HOTEL_MANAGEMENT_LIMIT_CHANGE"

