import * as ACTION_TYPES from './Types';

const initialState = {
    message: '',
    error: null,
    amenityList: { data: [], recordsTotal: 0 },
    amenityInfo: {},
    page: 1,
    limit: 10,
}

export const saAmenityReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_TYPES.AMENITYSUPER_LIST_SUCCESS:
            return {
                ...state,
                amenityList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.AMENITYSUPER_LIST_ERROR:
            return {
                ...state,
                hotelList: { data: [], recordsTotal: 0 },
            }

        case ACTION_TYPES.AMENITYSUPER_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.AMENITYSUPER_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.FETCH_AMENITYSUPER_INFO_SUCCESS:
            return {
                ...state,
                amenityInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.FETCH_AMENITYSUPER_INFO_ERROR:
            return {
                ...state,
                amenityInfo: {},
            }

        default:
            return { ...state }
    }

}