import * as ACTION_TYPES from './Types';

const initialState = {
  message: '',
  error: null,
  allUserList: { recordsTotal: 0, data: [] },
  userInfo: {},
  registrationUserInfo: {},
  page: 1,
  limit: 10
}

export const userReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.FETCH_ALL_USER_LIST_SUCCESS:
      return {
        ...state,
        allUserList: action.payload.data ? action.payload.data : { recordsTotal: 0, data: [] },
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_ALL_USER_LIST_ERROR:
      return {
        ...state,
        allUserList: { recordsTotal: 0, data: [] },
      }
    case ACTION_TYPES.USER_LIST_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload
      }
    case ACTION_TYPES.USER_LIST_LIMIT_CHANGE:
      return {
        ...state,
        limit: action.payload
      }
    case ACTION_TYPES.FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_REGISTRATION_LIST_SUCCESS:
      return {
        ...state,
        registrationList: action.payload.data,
        message: action.payload.message,
        error: null
      }

    default:
      return { ...state }
  }

}