import * as ACTION_TYPES from "./Types";

const initialState = {
  message: "",
  error: null,
  daysLogList: { recordsTotal: 0, data: [] },
  page: 1,
  length: 10,
};

export const daysLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOG_LIST_SUCCESS:
      return {
        ...state,
        daysLogList: action.payload.data,
        message: action.payload.message,
        error: null,
      };

    case ACTION_TYPES.DAYSLOG_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload,
      };
    case ACTION_TYPES.DAYLOG_LIMIT_CHANGE:
      return {
        ...state,
        limit: action.payload,
      };
    default:
      return { ...state };
  }
};
