
//FETCH USER LIST
export const FETCH_ALL_USER_LIST_SUCCESS = "FETCH_ALL_USER_LIST_SUCCESS";
export const FETCH_ALL_USER_LIST_ERROR = "FETCH_ALL_USER_LIST_ERROR";

//PAGE_CHANGE Actions
export const USER_LIST_PAGE_CHANGE = "USER_LIST_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const USER_LIST_LIMIT_CHANGE = "USER_LIST_LIMIT_CHANGE"

//USER Actions
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

//FETCH USER INFO 
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_ERROR = "FETCH_USER_INFO_ERROR";

//EDIT USER Actions
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";



//FETCH_PRODUCT Actions
export const FETCH_REGISTRATION_LIST_SUCCESS = "FETCH_REGISTRATION_LIST_SUCCESS";
export const FETCH_REGISTRATION_LIST_ERROR = "FETCH_REGISTRATION_LIST_ERROR";

//USER Actions
export const REJECT_USER_SUCCESS = "REJECT_USER_SUCCESS";
export const REJECT_USER_ERROR = "REJECT_USER_ERROR";



