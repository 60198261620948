import moment from "moment";

export const login = value => {
    let error = {};

    if (!value.email) { error.email = 'Email is required' }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.email)) {
        error.email = "Please enter valid email address."
    }
    if (!value.password) { error.password = 'Password is required' }
    return error;
}

export const changePassword = value => {
    let error = {};

    if (!value.currentPassword) { error.currentPassword = 'Enter current password' }
    if (!value.newPassword) { error.newPassword = 'Enter new password' }
    else if (value.newPassword === value.currentPassword) { error.newPassword = 'Current password and new password can not be same' }
    else if (value.newPassword.length < 6) { error.newPassword = "New password must contain at least 6 characters" }
    if (!value.repeatNewPassword) { error.repeatNewPassword = 'Re enter new password' }
    if (value.repeatNewPassword !== value.newPassword) { error.repeatNewPassword = 'New password and repeat password must be same' }

    return error;
}


export const resetPassword = value => {
    let error = {};

    if (!value.newPassword) { error.newPassword = 'Enter new Password' }
    else if (value.newPassword.length < 6) { error.newPassword = "New password must contain at least 6 characters" }
    if (!value.repeatNewPassword) { error.repeatNewPassword = 'Renter new Password' }
    if (value.repeatNewPassword !== value.newPassword) { error.repeatNewPassword = 'New password and repeat password must be same' }

    return error;
}

export const profile = value => {
    let error = {};

    if (!value.firstName || !value.firstName.trim()) {
        error.firstName = 'Please enter first name'
    }
    if (!value.lastName || !value.lastName.trim()) {
        error.lastName = 'Please enter last name'
    }
    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = 'Please enter phone number'
    }
    else if (!(value.phoneNumber.length === 10)) {
        error.phoneNumber = 'Enter valid phone number '
    }
    else if (/[^0-9]/i.test(value.phoneNumber)) {
        error.phoneNumber = 'Enter valid phone number'
    }

    return error;
}
export const notificationForm = value => {
    let error = {};
    if (!value.userType) { error.userType = 'Please select user type' }
    if (!value.specificUser || !value.specificUser.length) { error.specificUser = 'Please select users' }
    if (!value.linkedTab) { error.linkedTab = 'Please select linked tab' }
    if (!value.message) { error.message = 'Please enter message' }
    if (!value.repeat) { error.repeat = 'Please select repeat frequency' }
    if (!value.date) { error.date = 'Please select date' }
    return error;
}

export const userForm = value => {
    let error = {};

    if (!value.firstName) { error.firstName = 'Enter first name' }
    if (!value.lastName) { error.lastName = 'Enter last name' }
    if (!value.phoneNumber) { error.phoneNumber = 'Enter phone number' }
    else if (!(value.phoneNumber.length === 10)) {
        error.phoneNumber = 'Enter valid phone number '
    }
    else if (/[^0-9]/i.test(value.phoneNumber)) {
        error.phoneNumber = 'Enter valid phone number'
    }
    if (!value.email) { error.email = 'Enter email' }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.email)) {
        error.email = "Please enter valid email address."
    }
    if (!value.birthdayDate) { error.birthdayDate = 'Enter birth date' }

    return error;
}

export const AddAd = value => {
    let error = {};

    if (!value.stateId) { error.stateId = 'Please select state' }
    if (!value.url) { error.url = 'Please enter url' }

    let x = new Date(value.fromDate)
    let y = new Date(value.toDate)
    if (!value.fromDate) { error.fromDate = 'Please select start date' }
    else if (+x >= +y) {
        error.toDate = `Select date greater than ${moment(x).format('MM-DD-YYYY')}`
    }
    if (!value.toDate) { error.toDate = 'Please select end date' }
    else if (+x >= +y) {
        error.toDate = `Select date greater than ${moment(x).format('MM-DD-YYYY')}`
    }

    if (!value.banner) { error.banner = 'Please select banner image' }
    else if (Object.entries(value.banner).length === 0) {
        error.banner = 'Please choose one file'
    }

    return error;
}

export const editAd = value => {
    let error = {};

    if (!value.stateId) { error.stateId = 'Please select state' }
    if (!value.url) { error.url = 'Please enter url' }

    let x = new Date(value.fromDate)
    let y = new Date(value.toDate)
    if (!value.fromDate) { error.fromDate = 'Select start date' }
    else if (+x >= +y) {
        error.toDate = `Select date greater than ${moment(x).format('MM-DD-YYYY')}`
    }
    if (!value.toDate) { error.toDate = 'Select end date' }
    else if (+x >= +y) {
        error.toDate = `Select date greater than ${moment(x).format('MM-DD-YYYY')}`
    }
    // if (!value.banner) { error.banner = 'Please select banner' }
    // else if (Object.entries(value.banner).length === 0) {
    //     error.banner = 'Please choose one file'
    // }

    return error;
}

export const editDeal = value => {
    let error = {};

    if (!value.title) { error.title = 'Enter title' }
    if (!value.subTitle) { error.subTitle = 'Enter sub title' }
    if (!value.description) { error.description = 'Enter description' }
    if (!value.categoryId) { error.categoryId = 'Select category' }
    // let x = new Date(value.start_date)
    // let y = new Date(value.end_date)
    let x = value.start_date
    let y = value.end_date
    if (!value.start_date) { error.start_date = 'Select start date' }
    else if (+x >= +y) {
        error.end_date = `Select date greater than ${moment(x).format('MM-DD-YYYY')}`
    }
    if (!value.end_date) { error.end_date = 'Select end date' }
    else if (+x >= +y) {
        error.end_date = `Select date greater than ${moment(x).format('MM-DD-YYYY')}`
    }

    return error;
}

export const addDeal = value => {
    let error = {};

    if (!value.title) { error.title = 'Enter title' }
    if (!value.subTitle) { error.subTitle = 'Enter sub title' }
    if (!value.description) { error.description = 'Enter description' }
    if (!value.categoryId) { error.categoryId = 'Select category' }
    if (!value.claimedBusinessId ) { error.claimedBusinessId  = 'Select BusinessesId' }
    // let x = new Date(value.start_date)
    // let y = new Date(value.end_date)
    let x = value.start_date
    let y = value.end_date
    if (!value.start_date) { error.start_date = 'Select start date' }
    else if (+x >= +y) {
        error.end_date = `Select date greater than ${moment(x).format('MM-DD-YYYY')}`
    }
    if (!value.end_date) { error.end_date = 'Select end date' }
    else if (+x >= +y) {
        error.end_date = `Select date greater than ${moment(x).format('MM-DD-YYYY')}`
    }
    // if (!value.image) { error.image = 'Select image' }
    // else if (Object.entries(value.image).length === 0) {
    //     error.image = 'Please choose one file'
    // }

    return error;
}

export const addCard = value => {
    let error = {};

    if (!value.name) { error.name = 'Please enter name' }

    let chckDate = value.date && value.date.trim();
    if (!value.date) { error.date = 'Please enter exp date' }
    else if (chckDate.length < 5) {
        error.date = 'Please enter exp date'
    }

    let chckCvv = value.cvv && value.cvv.trim();
    if (!value.cvv) { error.cvv = 'Please enter cvv' }
    else if (chckCvv.length < 3) {
        error.cvv = 'Please enter cvv'
    }

    let chckNumber = value.cardNumber && value.cardNumber.trim();
    if (!value.cardNumber) { error.cardNumber = 'Please enter card number' }
    else if (chckNumber.length < 19) {
        error.cardNumber = 'Please enter card number'
    }

    return error;
}

export const catagory = value => {
    let error = {};

    if (!value.name) { error.name = 'Enter catagory name' }
    if (!value.image) { error.image = 'Select image' }
    else if (Object.entries(value.image).length === 0) {
        error.image = 'Please choose one file'
    }
    return error;
}

export const addHotel = value => {
    let error = {};

    if (!value.name || !value.name.trim()) { error.name = 'Enter hotel name' }
    if (!value.ownerName || !value.ownerName.trim()) { error.ownerName = 'Enter owner name' }
    if (!value.ownerEmail || !value.ownerEmail.trim()) { error.ownerEmail = 'Enter owner email' }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.ownerEmail)) {
        error.ownerEmail = "Please enter valid email address."
    }
    if (!value.description || !value.description.trim()) { error.description = 'Enter description' }
    if (!value.address || !value.address.trim()) { error.address = 'Enter address' }
    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = "Please enter Contact Number."
    }
    else if (value.phoneNumber.replace(/\D+/g, "").trim().length !== 10) {
        error.phoneNumber = "Should not be less than 10 digits."
    }
    // if (!value.laundryPhone || !value.laundryPhone.trim()) {
    //     error.laundryPhone = "Please enter Contact Number."
    // }
    // else if (value.laundryPhone.replace(/\D+/g, "").trim().length !== 10) {
    //     error.laundryPhone = "Should not be less than 10 digits."
    // }
    // if (!value.houseKeepingPhone || !value.houseKeepingPhone.trim()) {
    //     error.houseKeepingPhone = "Please enter Contact Number."
    // }
    // else if (value.houseKeepingPhone.replace(/\D+/g, "").trim().length !== 10) {
    //     error.houseKeepingPhone = "Should not be less than 10 digits."
    // }
    // if (!value.valetPhone || !value.valetPhone.trim()) {
    //     error.valetPhone = "Please enter Contact Number."
    // }
    // else if (value.valetPhone.replace(/\D+/g, "").trim().length !== 10) {
    //     error.valetPhone = "Should not be less than 10 digits."
    // }
    // if (!value.conciergePhone || !value.conciergePhone.trim()) {
    //     error.conciergePhone = "Please enter Contact Number."
    // }
    // else if (value.conciergePhone.replace(/\D+/g, "").trim().length !== 10) {
    //     error.conciergePhone = "Should not be less than 10 digits."
    // }
    // if (!value.shuttleTaxiPhone || !value.shuttleTaxiPhone.trim()) {
    //     error.shuttleTaxiPhone = "Please enter Contact Number."
    // }
    // else if (value.shuttleTaxiPhone.replace(/\D+/g, "").trim().length !== 10) {
    //     error.shuttleTaxiPhone = "Should not be less than 10 digits."
    // }
    return error;
}

export const editCatagory = value => {
    let error = {};

    if (!value.categoryName) { error.categoryName = 'Enter catagory name' }
    // if (!value.image) { error.image = 'Select image' }
    // else if (Object.entries(value.image).length === 0) {
    //     error.image = 'Please choose one file'
    // }
    return error;
}

export const editBusinessInfo = value => {
    let error = {};
    if (!value.name || !value.name.trim()) { error.name = 'Enter name' }
    if (!value.website || !value.website.trim()) { error.website = 'Enter Website' }
    if (!value.address || !value.address.trim()) { error.address = 'Enter address' }
    if (!value.description || !value.description.trim()) { error.description = 'Enter Description' }
    if (!value.phoneNumber) { error.phoneNumber = 'Enter phone number' }
    else if (!(value.phoneNumber.length === 10)) {
        error.phoneNumber = 'Enter valid phone number '
    }
    else if (/[^0-9]/i.test(value.phoneNumber)) {
        error.phoneNumber = 'Enter valid phone number'
    }
    // if (!value.image) { error.image = 'Please enter image' }
    // if (value.add) {
    //     if (!value.image) { error.image = 'Please choose one file' }
    //     else if (typeof value.image === 'object' && Object.entries(value.image).length <= 0) {
    //         error.image = 'Please choose one file'
    //     }
    // }
    return error;
}

export const editWaitingTime = value => {
    let error = {};
    if (!value.waitingTime) { error.waitingTime = 'Please enter waiting time' }
    return error;
}

export const bannerImage = value => {
    let error = {};

    if (!value.banner) { error.banner = 'Select image' }
    else if (Object.entries(value.banner).length === 0) {
        error.banner = 'Please choose one file'
    }
    return error;
}

export const amenity = value => {
    let error = {};

    if (!value.name) { error.name = 'Enter Amenity name' }
    if (!value.image) { error.image = 'Select image' }
    else if (Object.entries(value.image).length === 0) {
        error.image = 'Please choose one file'
    }
    return error;
}
export const claim = value => {
    let error = {};

    if (!value.name || !value.name.trim()) { error.name = 'Enter name' }
    if (!value.businessEmail) { error.businessEmail = 'Email is required' }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.businessEmail)) {
        error.businessEmail = "Please enter valid email address."
    }
    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = "Please enter Contact Number."
    }
    else if (value.phoneNumber.replace(/\D+/g, "").trim().length !== 10) {
        error.phoneNumber = "Should not be less than 10 digits."
    }
    return error;
}
