import React, { useRef } from "react";
import { CCol, CFormGroup, CInput, CLabel, CTextarea } from "@coreui/react";
import ReactDatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import Select2 from "react-dropdown-select";
import SelectMulti from "react-select";
import moment from "moment";
import CIcon from "@coreui/icons-react";
import QRCodeCanvas from "qrcode.react";

export const renderInput = ({
  input,
  type,
  autoComplete,
  placeholder,
  disabled,
  name,
  meta: { touched, error },
}) => {
  return (
    <>
      <CInput
        {...input}
        type={type}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
      {touched && error && (
        <h6 style={{ color: "red", textAlign: "right", width: "100%" }}>
          {error}
        </h6>
      )}
    </>
  );
};

export const renderCardInput = ({
  input,
  type,
  autoComplete,
  placeholder,
  className,
  disabled,
  name,
  meta: { touched, error },
}) => {
  return (
    <>
      <input
        {...input}
        type={type}
        name={name}
        className={className}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
      {touched && error && (
        <h6 style={{ color: "red", textAlign: "right", width: "100%" }}>
          {error}
        </h6>
      )}
    </>
  );
};

export const renderSelect = ({
  input,
  type,
  selectMap,
  placeholder,
  meta: { touched, error },
}) => {
  return (
    <>
      <select {...input} className="form-control" placeholder={placeholder}>
        {selectMap.map((value, index) => {
          return (
            <option key={index} value={value}>
              {value}
            </option>
          );
        })}
      </select>
      {touched && error && (
        <h6 style={{ color: "red", textAlign: "right", width: "100%" }}>
          {error}
        </h6>
      )}
    </>
  );
};

export const renderTextArea = ({
  input,
  placeholder,
  className,
  name,
  Message,
  meta: { touched, error },
}) => {
  return (
    <>
      <label>{Message}</label>
      <CCol>
        <CTextarea
          {...input}
          name={name}
          placeholder={placeholder}
          className={className}
        />
      </CCol>
      {touched && error && (
        <h6 style={{ color: "red", textAlign: "right" }}>{error}</h6>
      )}
    </>
  );
};

export const RenderFileField = ({
  input,
  brandPic,
  className,
  type,
  meta: { touched, error },
}) => {
  let ref = useRef();
  return (
    <>
      <div className="d-flex">
        {brandPic.src && (
          <div className="image-item">
            <img src={brandPic.src} alt={brandPic.alt} height="80px" />
          </div>
        )}
        <div
          onClick={(e) => {
            e.preventDefault();
          }}
          className="upload-input"
        >
          <button
            className="btn btn-dark"
            onClick={() => {
              ref.current.click();
            }}
          >
            {" "}
            <i className="fal fa-upload"></i>{" "}
          </button>
        </div>
      </div>
      <input
        {...input}
        ref={ref}
        className={"d-none"}
        type={type}
        accept="image/jpeg,image/png,image/jpg"
        value={undefined}
      />
      <div className="text-danger ml-1">
        {touched && error && (
          <h6
            className="mb-0 pl-2"
            style={{ color: "red", textAlign: "left", width: "100%" }}
          >
            {error}
          </h6>
        )}
      </div>
    </>
  );
};

export const SelectField = ({
  input,
  selectMap,
  formClass,
  placeholder,
  meta: { touched, error },
}) => {
  return (
    <div className={`form-group ${formClass}`}>
      <div className="form-block">
        <select {...input} className="form-control" placeholder={placeholder}>
          {selectMap.map((value, index) => {
            return (
              <option key={index} value={value.option}>
                {value.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="text-error text-danger">{touched && error && error}</div>
    </div>
  );
};

export const renderEditInputField = ({
  input,
  placeholder,
  disabled,
  type,
  labelName,
  isWithIcon,
  isHalf,
  pattern,
  meta: { touched, error },
}) => {
  return (
    <CCol md="6" lg={isHalf ? `6` : `4`}>
      {!isWithIcon ? (
        <label> {labelName}</label>
      ) : (
        <label>
          {" "}
          {labelName}
          <i
            className="fal fa-info ml-3 mb-1"
            style={{ cursor: "pointer" }}
            data-for="main"
            data-tip
            data-iscapture="true"
            // onClick={() => { setimage(toAbsoluteUrl(`/${imageName}`)); handleEnter.enter(); }}
          />
        </label>
      )}
      <CInput
        {...input}
        disabled={disabled}
        type={type}
        pattern={pattern}
        placeholder={placeholder}
      />
      {touched && error && (
        <h6
          className="mb-0"
          style={{ color: "red", textAlign: "right", width: "100%" }}
        >
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderEditInputFieldFull = ({
  input,
  placeholder,
  disabled,
  type,
  labelName,
  isWithIcon,
  isFull,
  pattern,
  meta: { touched, error },
}) => {
  return (
    <CCol md="6" lg={isFull ? `12` : `4`}>
      {!isWithIcon ? (
        <label> {labelName}</label>
      ) : (
        <label>
          {" "}
          {labelName}
          <i
            className="fal fa-info ml-3 mb-1"
            style={{ cursor: "pointer" }}
            data-for="main"
            data-tip
            data-iscapture="true"
            // onClick={() => { setimage(toAbsoluteUrl(`/${imageName}`)); handleEnter.enter(); }}
          />
        </label>
      )}
      <CInput
        {...input}
        disabled={disabled}
        type={type}
        pattern={pattern}
        placeholder={placeholder}
      />
      {touched && error && (
        <h6
          className="mb-0"
          style={{ color: "red", textAlign: "right", width: "100%" }}
        >
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const RenderEditDatePicker = ({
  input,
  placeholder,
  disabled,
  type,
  labelName,
  isHalf,
  minDate,
  pattern,
  meta: { touched, error },
}) => {
  return (
    <CCol md="6" lg={isHalf ? `6` : `4`}>
      <label>{labelName}</label> <br />
      <ReactDatePicker
        {...input}
        // selected={new Date(input.value) || new Date()}
        selected={input.value ? moment(input.value).toDate() : null}
        className="form-control"
        dateFormat="MM-dd-yyyy"
        minDate={minDate}
        disabledKeyboardNavigation
        isClearable={false}
        disabled={disabled}
        showYearDropdown={true}
        onChange={(value) => {
          input.onChange(value);
        }}
        onBlur={(e) => {
          e.preventDefault();
        }}
      />
      {touched && error && (
        <h6
          className="mb-0"
          style={{ color: "red", textAlign: "right", width: "100%" }}
        >
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderEditTextareaField = ({
  input,
  placeholder,
  disabled,
  labelName,
  rows,
  isFull,
  meta: { touched, error },
}) => {
  return (
    <CCol md="6" lg={isFull ? `12` : `4`}>
      <label>{labelName}</label>
      <CTextarea
        {...input}
        rows={rows}
        disabled={disabled}
        placeholder={placeholder}
      />
      {touched && error && (
        <h6
          className="mb-0"
          style={{ color: "red", textAlign: "right", width: "100%" }}
        >
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderQr = ({
  input,
  uniqueCode,
  labelName,
  rows,
  isFull,
  meta: { touched, error },
}) => {
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${uniqueCode}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <CCol md="6" lg={"3"}>
      <label style={{ display: "block" }}>{labelName}</label>
      {/* <CFormLabel htmlFor="artistname">Artist Name</CFormLabel> */}
      {uniqueCode && (
        <QRCodeCanvas
          id="qr-gen"
          rows={rows}
          value={uniqueCode}
          size={200}
          level={"H"}
          includeMargin={true}
        />
      )}
      {/* <QRCodeCanvas value="https://reactjs.org/" />, */}
      <p>
        Click to{" "}
        <button type="button" onClick={downloadQRCode}>
          Download QR Code
        </button>
      </p>
      {touched && error && (
        <h6
          className="mb-0"
          style={{ color: "red", textAlign: "right", width: "100%" }}
        >
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderEditSelectField = ({
  input,
  disabled,
  placeholder,
  selectMap,
  labelName,
  meta: { touched, error },
}) => {
  return (
    <CCol>
      <label>{labelName}</label>
      <select
        {...input}
        disabled={disabled}
        className="form-control"
        placeholder={placeholder}
      >
        {selectMap.map((value, index) => {
          return (
            <option key={index} value={value.option}>
              {value.address ? value.name + " - " + value.address : value.name}
            </option>
          );
        })}
      </select>
      {touched && error && (
        <h6
          className="mb-0"
          style={{ color: "red", textAlign: "right", width: "100%" }}
        >
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderReactSelect2 = ({
  input,
  isMulti,
  selectMap,
  className,
  isFull,
  labelName,
  meta: { touched, error },
  defaultValue,
}) => {
  console.log(input);
  return (
    <CCol lg={isFull && `12`}>
      <label>{labelName}</label>
      <Select2
        {...input}
        values={selectMap && selectMap.length && defaultValue ? selectMap.filter(v => v._id === defaultValue) : []}
        options={selectMap.length > 0 && selectMap}
        className={className}
        color={"#405a8b"}
        multi={isMulti}
        onChange={(value) => {
          input.onChange(value);
        }}
      />
      {touched && error && (
        <h6
          className="mb-0"
          style={{ color: "red", textAlign: "right", width: "100%" }}
        >
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderReactSelectMulti = ({
  input,
  isMulti,
  selectMap,
  className,
  isFull,
  labelName,
  disabled,
  meta: { touched, error },
}) => {
  return (
    <CCol lg={isFull && `12`}>
      <label>{labelName}</label>
      <SelectMulti
        {...input}
        options={selectMap.length > 0 && selectMap}
        className={className}
        color={"#405a8b"}
        isMulti={isMulti}
        isSearchable={true}
        isDisabled={disabled}
        onBlur={() => input.onBlur()}
        onFocus={() => input.onFocus()}
        onChange={(value) => {
          input.onChange(value);
        }}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? "All Selected" : "No results found"
        }
      />
      {touched && error && (
        <h6
          className="mb-0"
          style={{ color: "red", textAlign: "right", width: "100%" }}
        >
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderEditFileField = ({
  input,
  // meta: { touched, error }
}) => {
  return (
    <>
      <CInput
        {...input}
        type="file"
        name="name"
        value={undefined}
        accept=".pdf"
        placeholder="Sales Tax Permit"
      />
      {/* <h6 className="mb-0" style={{ color: 'red', textAlign: 'right', width: '100%' }} >error</h6> */}
    </>
  );
};

export const renderNumber = ({
  input,
  type,
  styleField,
  className,
  placeholder,
  disabled,
  formatField,
  meta: { touched, error },
}) => {
  return (
    <>
      <NumberFormat
        {...input}
        format={formatField}
        style={styleField}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
      />
      {touched && error && (
        <h6 style={{ color: "red", textAlign: "right", width: "100%" }}>
          {error}
        </h6>
      )}
    </>
  );
};

export const renderNumberField1 = ({
  input,
  labelName,
  type,
  icon,
  isHalf,
  isWithIcon,
  isDisabled,
  meta: { touched, error, warning },
}) => (
  // <CFormGroup row>
  //   <CCol md="3">
  //     <CLabel htmlFor="text-input">{label}</CLabel>
  //   </CCol>
  //   <CCol xs="12" md="6">
  //     <NumberFormat {...input} format="(###) ###-####" type={type} placeholder={label} disabled={isDisabled} className="form-control" />
  //     <div className="text-danger">
  //       {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
  //     </div>
  //   </CCol>
  // </CFormGroup>
  <CCol md="6" lg={isHalf ? `6` : `4`}>
    {!isWithIcon ? (
      <label> {labelName}</label>
    ) : (
      <label>
        {" "}
        {labelName}
        <i
          className="fal fa-info ml-3 mb-1"
          style={{ cursor: "pointer" }}
          data-for="main"
          data-tip
          data-iscapture="true"
          // onClick={() => { setimage(toAbsoluteUrl(`/${imageName}`)); handleEnter.enter(); }}
        />
      </label>
    )}
    <NumberFormat
      {...input}
      format="(###) ###-####"
      type={type}
      placeholder={labelName}
      disabled={isDisabled}
      className="form-control"
    />
    {touched && error && (
      <h6
        className="mb-0"
        style={{ color: "red", textAlign: "right", width: "100%" }}
      >
        {error}
      </h6>
    )}
  </CCol>
);
export const renderNumberField2 = ({
  input,
  labelName,
  type,
  icon,
  isHalf,
  isWithIcon,
  isDisabled,
  meta: { touched, error, warning },
}) => {
  return (
    <>
      <NumberFormat
        {...input}
        format="(###) ###-####"
        type={type}
        placeholder={labelName}
        disabled={isDisabled}
        className="form-control"
      />
      {touched && error && (
        <h6 style={{ color: "red", textAlign: "right", width: "100%" }}>
          {error}
        </h6>
      )}
    </>
  );
};

export const RenderFileField88 = ({
  input,
  brandPic,
  className,
  type,
  onDelete,
  meta: { touched, error },
}) => {
  let ref = useRef();
  return (
    <>
      <div className="d-flex">
        {brandPic.src && (
          <div className="image-item">
            <img src={brandPic.src} alt={brandPic.alt} height="80px" />
            <span
              className="upload-remove"
              onClick={(e) => {
                e.preventDefault();
                onDelete();
              }}
            >
              <CIcon size="lg" title="Delete" name="cil-trash"></CIcon>
            </span>
          </div>
        )}
        <div
          onClick={(e) => {
            e.preventDefault();
          }}
          className="upload-input"
        >
          <button
            className="btn btn-dark"
            onClick={() => {
              ref.current.click();
            }}
          >
            {" "}
            <i className="fal fa-upload"></i>{" "}
          </button>
        </div>
      </div>
      <input
        id="mm"
        {...input}
        ref={ref}
        className={"d-none"}
        type={type}
        accept="image/jpeg,image/png,image/jpg"
        value={undefined}
      />
      <div className="text-danger ml-1">
        {touched && error && (
          <h6
            className="mb-0 pl-2"
            style={{ color: "red", textAlign: "left", width: "100%" }}
          >
            {error}
          </h6>
        )}
      </div>
    </>
  );
};
