import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../config/AppConstant'


// To login
export const login = user => dispatch => {
    return axios
        .post(`${API_BASE}auth/login`, { ...user })
        .then((res) => {
            if (res.data.status === 200) {
                axios.defaults.headers.common['authorization'] = res.data.data.accessToken;
                dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, payload: res.data })
            }
            else { dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: res.data }) }
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: err.response })
            throw err
        })
}

// Change Password SuperAdmin
export const changePasswordSuperAdmin = data => dispatch => {
    return axios
        .post(`${API_BASE}api/changePassword`, data)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.CHANGE_PASSWORD_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.CHANGE_PASSWORD_ERROR, payload: err.response })
            throw err
        })
}

// Change Password Buisness Admin
export const changePasswordBuisnessAdmin = data => dispatch => {
    return axios
        .post(`${API_BASE}api/changePasswordBusinessAdmin`, data)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.CHANGE_PASSWORD_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.CHANGE_PASSWORD_ERROR, payload: err.response })
            throw err
        })
}

// Reset Password
export const resetPassword = data => dispatch => {
    return axios
        .post(`${API_BASE}auth/resetPassword`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// logout super admin
export const logoutSuperAdmin = (post) => dispatch => {
    return axios
        .get(`${API_BASE}api/logOut`)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.LOGOUT, payload: res.data })
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// logout buisness admin
export const logoutAdmin = (post) => dispatch => {
    return axios
        .get(`${API_BASE}api/logOut`)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.LOGOUT, payload: res.data })
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

//To reAuthenticate if the cookie is not expired
export const authenticate = user => dispatch => {
    if (user) {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_USER, payload: user })
    } else {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED })
    }
}

// Forgot Password
export const forgotPassword = data => dispatch => {
    return axios
        .post(`${API_BASE}auth/forgotPassword`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}


// get app data settings
export const getAppDataSettings = () => dispatch => {
    return axios
        .get(`${API_BASE}api/getAppData`)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_APP_DATA_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_APP_DATA_ERROR, payload: err.response })
            throw err
        })
}

// update app data settings
export const updateAppDataSettings = (data) => dispatch => {
    return axios
        .post(`${API_BASE}api/updateAppData`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

// get profile
export const getProfile = (type) => dispatch => {
    return axios
        .get(`${API_BASE}api/getAdminProfile`)
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_PROFILE_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_PROFILE_ERROR, payload: err.response })
            throw err
        })
}

export const editProfile = (data) => dispatch => {
    return axios
        .post(`${API_BASE}api/updateAdmin`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

//accept t&c
export const accepttc = (data) => dispatch => {
    return axios
        .post(`${API_BASE}api/acceptTnC`, data)
        .then((res) => {
            return res.data
        })
        .catch((err) => {
            throw err
        })
}

//get t&c
export const gettc = (data) => dispatch => {
    return axios
        .post(`${API_BASE}api/getCmsPageInfo`, { pageName: data })
        .then((res) => {
            dispatch({ type: ACTION_TYPES.GET_TC_SUCCESS, payload: res.data })
            return res.data
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_TC_ERROR, payload: err.response })
            throw err
        })
}