import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import './config/AxiosConfig';
import store from './Redux/store'
import { toast } from 'react-toastify'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import * as ACTIONS from './Redux/login/Action'
import { AUTHENTICATE_FAILED } from './Redux/login/Types';
import checkAuth from './Authentication/Auth'
import ReduxToastr from 'react-redux-toastr';
import "react-datepicker/dist/react-datepicker.css";

import ResetPassword from './views/pages/reset-password/ResetPassword';

const loading = () => (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const TheLayout = lazy(() => import('./containers/TheLayout'));

// Pages
const Login = lazy(() => import('./views/pages/login/Login'));
const ForgotPassword = lazy(() => import('./views/pages/forgot-password/ForgotPassword'));
const Page404 = lazy(() => import('./views/pages/page404/Page404'));
const Page500 = lazy(() => import('./views/pages/page500/Page500'));
const AcceptTC = lazy(() => import('./views/pages/login/Modal'));
toast.configure()

const token = JSON.parse(localStorage.getItem("authTokenFGD"));
const loggedInUser = JSON.parse(localStorage.getItem("userFGD"));


if (token && loggedInUser) {
    store.dispatch(ACTIONS.authenticate(loggedInUser));
} else {
    store.dispatch({ type: AUTHENTICATE_FAILED });
}

export const App = () => {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ReduxToastr />
            <Suspense fallback={loading()}>
                <Switch>
                    <Route exact path="/" name="Login Page" render={props => <Login {...props} />} />
                    <Route exact path="/acceptTC" name="Accept T&C" component={checkAuth(AcceptTC)} />
                    <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
                    <Route exact path="/reset-password/:resetCode" name="Reset Password" render={props => <ResetPassword {...props} />} />
                    <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                    <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                    <Route name="Home" component={checkAuth(TheLayout)} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
