import * as ACTION_TYPES from './Types';

const initialState = {
  message: '',
  error: null,
  sAdminDealsList: { recordsTotal: 0, data: [] },
  bAdminDealsList: { recordsTotal: 0, data: [] },
  dealInfo: {},
  categoryList: { data: [] },
  page: 1,
  length: 10
}

export const dealReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.SUPER_ADMIN_DEALS_LIST_SUCCESS:
      return {
        ...state,
        sAdminDealsList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.B_ADMIN_DEALS_LIST_SUCCESS:
      return {
        ...state,
        bAdminDealsList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_DEALS_INFO_SUCCESS:
      return {
        ...state,
        dealInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.CATEGORY_LOOKUP_LIST_SUCCESS:
      return {
        ...state,
        categoryList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.DEALS_LIST_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload
      }
    case ACTION_TYPES.DEALS_LIST_LIMIT_CHANGE:
      return {
        ...state,
        length: action.payload
      }

    default:
      return { ...state }
  }

}