import * as ACTION_TYPES from './Types';

const initialState = {
  claimList: { data: [], recordsTotal: 0 },
  cpage: 1,
  clength: 10,

  aproovedList: { data: [], recordsTotal: 0 },
  apage: 1,
  alength: 10,

  allBusinessList: { data: [], recordsTotal: 0 },
  aBpage: 1,
  aBlength: 10,

  message: '',
  error: null
}

export const businessReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.FETCH_CLAIMED_BUISNESS_LIST_SUCCESS:
      return {
        ...state,
        claimList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.CLAIM_MANAGEMENT_PAGE_CHANGE:
      return {
        ...state,
        cpage: action.payload
      }
    case ACTION_TYPES.CLAIM_MANAGEMENT_LIMIT_CHANGE:
      return {
        ...state,
        clength: action.payload
      }

    case ACTION_TYPES.FETCH_APROOVED_BUISNESS_LIST_SUCCESS:
      return {
        ...state,
        aproovedList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.APRROVED_MANAGEMENT_PAGE_CHANGE:
      return {
        ...state,
        apage: action.payload
      }
    case ACTION_TYPES.APRROVED_MANAGEMENT_LIMIT_CHANGE:
      return {
        ...state,
        alength: action.payload
      }
    case ACTION_TYPES.FETCH_ALL_BUISNESS_LIST_SUCCESS:
      return {
        ...state,
        allBusinessList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_ALL_BUISNESS_LIST_ERROR:
      return {
        ...state,
        allBusinessList: { data: [], recordsTotal: 0 },
        error: null
      }

    case ACTION_TYPES.ALL_BUSINESS_MANAGEMENT_PAGE_CHANGE:
      return {
        ...state,
        aBpage: action.payload
      }
    case ACTION_TYPES.ALL_BUSINESS_MANAGEMENT_LIMIT_CHANGE:
      return {
        ...state,
        aBlength: action.payload
      }
    default:
      return { ...state }
  }

}