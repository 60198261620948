
//FETCH CLAIMED Actions
export const FETCH_CLAIMED_BUISNESS_LIST_SUCCESS = "FETCH_CLAIMED_BUISNESS_LIST_SUCCESS";
export const FETCH_CLAIMED_BUISNESS_LIST_ERROR = "FETCH_CLAIMED_BUISNESS_LIST_ERROR";

//PAGE_CHANGE Actions
export const CLAIM_MANAGEMENT_PAGE_CHANGE = "CLAIM_MANAGEMENT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const CLAIM_MANAGEMENT_LIMIT_CHANGE = "CLAIM_MANAGEMENT_LIMIT_CHANGE"

//FETCH APROOVED
export const FETCH_APROOVED_BUISNESS_LIST_SUCCESS = "FETCH_APROOVED_BUISNESS_LIST_SUCCESS";
export const FETCH_APROOVED_BUISNESS_LIST_ERROR = "FETCH_APROOVED_BUISNESS_LIST_ERROR";

//PAGE_CHANGE Actions
export const APRROVED_MANAGEMENT_PAGE_CHANGE = "APRROVED_MANAGEMENT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const APRROVED_MANAGEMENT_LIMIT_CHANGE = "APRROVED_MANAGEMENT_LIMIT_CHANGE"

//FETCH ALL
export const FETCH_ALL_BUISNESS_LIST_SUCCESS = "FETCH_ALL_BUISNESS_LIST_SUCCESS";
export const FETCH_ALL_BUISNESS_LIST_ERROR = "FETCH_ALL_BUISNESS_LIST_ERROR";

//PAGE_CHANGE Actions
export const ALL_BUSINESS_MANAGEMENT_PAGE_CHANGE = "ALL_BUSINESS_MANAGEMENT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const ALL_BUSINESS_MANAGEMENT_LIMIT_CHANGE = "ALL_BUSINESS_MANAGEMENT_LIMIT_CHANGE"
