//GET CMS LIST
export const GET_CMS_LIST_SUCCESS = "GET_CMS_LIST_SUCCESS";
export const GET_CMS_LIST_ERROR = "GET_CMS_LIST_ERROR";

//CMS INFO
export const GET_CMS_INFO_SUCCESS = "GET_CMS_INFO_SUCCESS";
export const GET_CMS_INFO_ERROR = "GET_CMS_INFO_ERROR";

//EDIT CMS 
export const EDIT_CMS_SUCCESS = "EDIT_CMS_SUCCESS";
export const EDIT_CMS_ERROR = "EDIT_CMS_ERROR";

