import * as ACTION_TYPES from './Types';

const initialState = {
  waitingTime: { waitingTime: null },
  message: '',
  error: null,
}

export const waitingTimeReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.GET_WAITING_TIME_SUCCESS:
      return {
        ...state,
        waitingTime: action.payload.data,
        message: action.payload.message,
        error: null
      }

    default:
      return { ...state }
  }

}