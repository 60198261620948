
//FETCH CATAGORY
export const FETCH_HOTEL_LIST_SUCCESS = "FETCH_HOTEL_LIST_SUCCESS";
export const FETCH_HOTEL_LIST_ERROR = "FETCH_HOTEL_LIST_ERROR";

//FETCH CATAGORY
export const FETCH_AMENITY_LOOKUP_SUCCESS = "FETCH_AMENITY_LOOKUP_SUCCESS";
export const FETCH_AMENITY_LOOKUP_ERROR = "FETCH_AMENITY_LOOKUP_ERROR";

//PAGE_CHANGE Actions
export const HOTEL_MANAGEMENT_PAGE_CHANGE = "HOTEL_MANAGEMENT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const HOTEL_MANAGEMENT_LIMIT_CHANGE = "HOTEL_MANAGEMENT_LIMIT_CHANGE"

//CATAGORY_INFO
export const FETCH_HOTEL_INFO_SUCCESS = "FETCH_HOTEL_INFO_SUCCESS";
export const FETCH_HOTEL_INFO_ERROR = "FETCH_HOTEL_INFO_ERROR";
//get all users
export const FETCH_HOTEL_USER_SUCCESS = "FETCH_HOTEL_USER_SUCCESS";
export const FETCH_HOTEL_USER_ERROR = "FETCH_HOTEL_USER_ERROR";

//get all notification list
export const FETCH_ALL_NOTIFICATION_SUCCESS = "FETCH_ALL_NOTIFICATION_SUCCESS";
export const FETCH_ALL_NOTIFICATION_ERROR = "FETCH_ALL_NOTIFICATION_ERROR";
export const HOTEL_NOTIFICATION_PAGE_CHANGE = "HOTEL_NOTIFICATION_PAGE_CHANGE"
export const HOTEL_NOTIFICATION_LIMIT_CHANGE = "HOTEL_NOTIFICATION_LIMIT_CHANGE"
//get notification details
export const FETCH_NOTIFICATION_DETAILS_SUCCESS = "FETCH_NOTIFICATION_DETAILS_SUCCESS";
export const FETCH_NOTIFICATION_DETAILS_ERROR = "FETCH_NOTIFICATION_DETAILS_ERROR";