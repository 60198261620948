import * as ACTION_TYPES from './Types';

const initialState = {
  cmsList: { data: [] },
  cmsInfo: [{}],
  error: null
}

export const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_CMS_LIST_SUCCESS:
      return {
        ...state,
        cmsList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_CMS_INFO_SUCCESS:
      return {
        ...state,
        cmsInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    default:
      return state;
  }
}
