
//FETCH CATAGORY
export const FETCH_CATAGORY_LIST_SUCCESS = "FETCH_CATAGORY_LIST_SUCCESS";
export const FETCH_CATAGORY_LIST_ERROR = "FETCH_CATAGORY_LIST_ERROR";

//DELETE CATAGORY Action
export const DELETE_CATAGORY_SUCCESS = "DELETE_CATAGORY_SUCCESS";
export const DELETE_CATAGORY_ERROR = "DELETE_CATAGORY_ERROR";

//PAGE_CHANGE Actions
export const CATAGORY_MANAGEMENT_PAGE_CHANGE = "CATAGORY_MANAGEMENT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const CATAGORY_MANAGEMENT_LIMIT_CHANGE = "CATAGORY_MANAGEMENT_LIMIT_CHANGE"

//CATAGORY_INFO
export const FETCH_CATAGORY_INFO_SUCCESS = "FETCH_CATAGORY_INFO_SUCCESS";
export const FETCH_CATAGORY_INFO_ERROR = "FETCH_CATAGORY_INFO_ERROR";

