import * as ACTION_TYPES from './Types';

const initialState = {
  message: '',
  error: null,
  brandList: { brand_list: [], recordsTotal: 0 },
  catagoryList: { data: [], recordsTotal: 0 },
  catagoryInfo: {},
  brandInfo: {},
  page: 1,
  limit: 10,
}

export const catagoryReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.FETCH_CATAGORY_LIST_SUCCESS:
      return {
        ...state,
        catagoryList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_CATAGORY_LIST_ERROR:
      return {
        ...state,
        catagoryList: { recordsTotal: 0, data: [] },
      }
    case ACTION_TYPES.CATAGORY_MANAGEMENT_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload
      }
    case ACTION_TYPES.CATAGORY_MANAGEMENT_LIMIT_CHANGE:
      return {
        ...state,
        limit: action.payload
      }
    case ACTION_TYPES.FETCH_CATAGORY_INFO_SUCCESS:
      return {
        ...state,
        catagoryInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    default:
      return { ...state }
  }

}