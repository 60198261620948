import * as ACTION_TYPES from './Types';

const initialState = {
  message: '',
  error: null,
  hotelList: { data: [], recordsTotal: 0 },
  hotelInfo: {},
  checkingLog: {},
  checkingLogPage: 1,
  checkingLogLimit: 10,
  page: 1,
  limit: 10,
}

export const saHotelReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.FETCH_HOTEL_LIST_SUCCESS:
      return {
        ...state,
        hotelList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_HOTEL_LIST_ERROR:
      return {
        ...state,
        hotelList: { data: [], recordsTotal: 0 },
      }
    
    case ACTION_TYPES.HOTEL_CHECKING_LOG_SUCCESS:
      return {
        ...state,
        checkingLog: action.payload.data,
        message: action.payload.message,
        error: null
      }
  
    case ACTION_TYPES.FETCH_HOTEL_LIST_ERROR:
      return {
        ...state,
        checkingLog: { },
      }
    case ACTION_TYPES.HOTEL_MANAGEMENT_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload
      }

    case ACTION_TYPES.HOTEL_CHECKING_LOG_PAGE_CHANGE:
      return {
        ...state,
        checkingLogPage: action.payload
      }
    case ACTION_TYPES.HOTEL_MANAGEMENT_LIMIT_CHANGE:
      return {
        ...state,
        limit: action.payload
      }
    case ACTION_TYPES.HOTEL_CHECKING_LOG_LIMIT_CHANGE:
    return {
      ...state,
      checkingLogLimit: action.payload
    }
    case ACTION_TYPES.FETCH_HOTEL_INFO_SUCCESS:
      return {
        ...state,
        hotelInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_HOTEL_INFO_ERROR:
      return {
        ...state,
        hotelInfo: {},
      }

    default:
      return { ...state }
  }

}