import * as ACTION_TYPES from './Types';

const initialState = {
  image: '',
  message: '',
  error: null,
}

export const businessInfoReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.GET_BUSINESS_IMAGE_SUCCESS:
      return {
        ...state,
        image: action.payload.data,
        message: action.payload.message,
        error: null
      }

    default:
      return { ...state }
  }

}