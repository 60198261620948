
//FETCH DEALS LIST
export const SUPER_ADMIN_DEALS_LIST_SUCCESS = "SUPER_ADMIN_DEALS_LIST_SUCCESS";
export const SUPER_ADMIN_DEALS_LIST_ERROR = "SUPER_ADMIN_DEALS_LIST_ERROR";

export const B_ADMIN_DEALS_LIST_SUCCESS = "B_ADMIN_DEALS_LIST_SUCCESS";
export const B_ADMIN_DEALS_LIST_ERROR = "B_ADMIN_DEALS_LIST_ERROR";

export const CATEGORY_LOOKUP_LIST_SUCCESS = "CATEGORY_LOOKUP_LIST_SUCCESS";
export const CATEGORY_LOOKUP_LIST_ERROR = "CATEGORY_LOOKUP_LIST_ERROR";

//PAGE_CHANGE Actions
export const DEALS_LIST_PAGE_CHANGE = "DEALS_LIST_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const DEALS_LIST_LIMIT_CHANGE = "DEALS_LIST_LIMIT_CHANGE"

//FETCH DEALS INFO 
export const FETCH_DEALS_INFO_SUCCESS = "FETCH_DEALS_INFO_SUCCESS";
export const FETCH_DEALS_INFO_ERROR = "FETCH_DEALS_INFO_ERROR";
