import * as ACTION_TYPES from './Types';

const initialState = {
  flaggedBusinessList: { data: [], recordsTotal: 0 },
  bpage: 1,
  blength: 10,

  flaggedDealList: { data: [], recordsTotal: 0 },
  dpage: 1,
  dlength: 10,

  message: '',
  error: null
}

export const flaggedReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.FETCH_FLAGGED_BUSINESS_LIST_SUCCESS:
      return {
        ...state,
        flaggedBusinessList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_FLAGGED_BUSINESS_LIST_ERROR:
      return {
        ...state,
        flaggedBusinessList: { data: [], recordsTotal: 0 },
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FLAGGED_BUSINESS_PAGE_CHANGE:
      return {
        ...state,
        bpage: action.payload
      }
    case ACTION_TYPES.FLAGGED_BUSINESS_LIMIT_CHANGE:
      return {
        ...state,
        blength: action.payload
      }
    case ACTION_TYPES.FETCH_FLAGGED_DEAL_LIST_SUCCESS:
      return {
        ...state,
        flaggedDealList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FLAGGED_DEAL_PAGE_CHANGE:
      return {
        ...state,
        dpage: action.payload
      }
    case ACTION_TYPES.FLAGGED_DEAL_LIMIT_CHANGE:
      return {
        ...state,
        dlength: action.payload
      }

    default:
      return { ...state }
  }

}