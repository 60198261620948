import * as ACTION_TYPES from './Types';

const initialState = {
  notificationUserList: { data: [], recordsTotal: 0 },
  notificationType: { data: [] },
  message: '',
  error: null,
}

export const notificationReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.NOTIFICATION_USER_LIST_SUCCESS:
      return {
        ...state,
        notificationUserList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.NOTIFICATION_TYPE_SUCCESS:
      return {
        ...state,
        notificationType: action.payload.data,
        message: action.payload.message,
        error: null
      }

    default:
      return { ...state }
  }

}