
//FETCH CATAGORY
export const FETCH_HOTEL_LIST_SUCCESS = "FETCH_HOTEL_LIST_SUCCESS";
export const FETCH_HOTEL_LIST_ERROR = "FETCH_HOTEL_LIST_ERROR";

//PAGE_CHANGE Actions
export const HOTEL_MANAGEMENT_PAGE_CHANGE = "HOTEL_MANAGEMENT_PAGE_CHANGE"

//LIMIT_CHANGE Actions
//HOTEL CHECKING PAGE CHNAGE
export const HOTEL_MANAGEMENT_LIMIT_CHANGE = "HOTEL_MANAGEMENT_LIMIT_CHANGE"

//CATAGORY_INFO
export const FETCH_HOTEL_INFO_SUCCESS = "FETCH_HOTEL_INFO_SUCCESS";
export const FETCH_HOTEL_INFO_ERROR = "FETCH_HOTEL_INFO_ERROR";

//HOTEL CHECKING LOG
export const HOTEL_CHECKING_LOG_SUCCESS = "HOTEL_CHECKING_LOG_SUCCESS";
export const HOTEL_CHECKING_LOG_ERROR = "HOTEL_CHECKING_LOG_ERROR";

//HOTEL CHECKING PAGE CHNAGE
export const HOTEL_CHECKING_LOG_PAGE_CHANGE = "HOTEL_CHECKING_LOG_PAGE_CHANGE";

//HOTEL CHECKING LIMIT CHNAGE
export const HOTEL_CHECKING_LOG_LIMIT_CHANGE = "HOTEL_CHECKING_LOG_LIMIT_CHANGE"



