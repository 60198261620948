import * as ACTION_TYPES from './Types';

const initialState = {
  cardList: { data: [] },
  subscriptionInfo: {},
  message: '',
  error: null,
}

export const buisnessSubscriptonReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.CARD_LIST_SUCCESS:
      return {
        ...state,
        cardList: action.payload.data ? action.payload.data : { data: [] },
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.CARD_LIST_ERROR:
      return {
        ...state,
        cardList: { data: [] },
        error: null
      }
    case ACTION_TYPES.SUBSCRITPION_INFO_SUCCESS:
      return {
        ...state,
        subscriptionInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.SUBSCRITPION_INFO_ERROR:
      return {
        ...state,
        subscriptionInfo: {},
        error: null
      }
    default:
      return { ...state }
  }

}