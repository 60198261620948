import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Auth(ComposedComponent) {

    const Authentication = props => {

        const { location } = props.history;

        const userData = useSelector(state => state.login.userData)
        const isLoginState = useSelector(state => state.login.isLoggedIn)
        const isSubscribed = useSelector(state => state.bSubscription.subscriptionInfo.isSubscribe)

        // let pathSuperAdmin = ['/deal-edit', '/deal-add', '/set-waiting-time', '/subscription', '/add-card', '/list-of-cards', '/business-info'];
        // let pathBuisnessAdmin = ['/user-management', '/category-management', '/cms-management', '/notification-management', '/claim-requests', '/approved-businesses', '/app-data-management', '/ad-management','/sa-list-amenity','/sa-hotel-management']
        let pathUnsubscribedBusinessAdmin = ['/deal-management', '/deal-edit', '/deal-add', ' /set-waiting-time'];

        useEffect(() => {

            if (!isLoginState) {
                props.history.push('/')
            }

            pathUnsubscribedBusinessAdmin.map((val) => {
                return ((location.pathname.startsWith(val) && userData.userType === 3 && isSubscribed === false) && props.history.push('/subscription'))
            })

            // pathBuisnessAdmin.map((val) => {
            //     return ((location.pathname.startsWith(val) && userData.userType === 'admin' && isSubscribed) && props.history.push('/deal-management'))
            // })

            // pathSuperAdmin.map((val) => {
            //     return ((location.pathname.startsWith(val) && userData.userType === 'superadmin') && props.history.push('/user-management'))
            // })

            // eslint-disable-next-line
        }, [isLoginState])

        return <ComposedComponent {...props} />
    }

    return withRouter((Authentication))
}
