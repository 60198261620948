
//FETCH Ad LIST
export const AD_LIST_SUCCESS = "AD_LIST_SUCCESS";
export const AD_LIST_ERROR = "AD_LIST_ERROR";

export const STATE_LOOKUP_LIST_SUCCESS = "STATE_LOOKUP_LIST_SUCCESS";
export const STATE_LOOKUP_LIST_ERROR = "STATE_LOOKUP_LIST_ERROR";

//PAGE_CHANGE Actions
export const AD_LIST_PAGE_CHANGE = "AD_LIST_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const AD_LIST_LIMIT_CHANGE = "AD_LIST_LIMIT_CHANGE"

//FETCH AD INFO 
export const FETCH_AD_INFO_SUCCESS = "FETCH_AD_INFO_SUCCESS";
export const FETCH_AD_INFO_ERROR = "FETCH_AD_INFO_ERROR";
