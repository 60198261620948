import * as ACTION_TYPES from './Types';

const initialState = {
  message: '',
  error: null,
  message1: '',
  error1: null,
  hotelList: { data: [], recordsTotal: 0 },
  hotelInfo: {},
  amenities: { Amenities: [] },
  allUsers: {},
  allNotification: { data: [], recordsTotal: 0 },
  notificationDetail: {},
  page: 1,
  limit: 10,
  page1: 1,
  limit1: 10,
}

export const haHotelReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.FETCH_HOTEL_LIST_SUCCESS:
      return {
        ...state,
        hotelList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        allNotification: action.payload.data,
        message1: action.payload.message,
        error1: null
      }
    case ACTION_TYPES.FETCH_HOTEL_USER_SUCCESS:
      return {
        ...state,
        allUsers: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_HOTEL_LIST_ERROR:
      return {
        ...state,
        hotelList: { data: [], recordsTotal: 0 },
      }
    case ACTION_TYPES.FETCH_ALL_NOTIFICATION_ERROR:
      return {
        ...state,
        allNotification: { data: [], recordsTotal: 0 },
      }
    case ACTION_TYPES.FETCH_HOTEL_USER_ERROR:
      return {
        ...state,
        allUsers: {},
      }
    case ACTION_TYPES.HOTEL_MANAGEMENT_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload
      }
    case ACTION_TYPES.HOTEL_MANAGEMENT_LIMIT_CHANGE:
      return {
        ...state,
        limit: action.payload
      }
    case ACTION_TYPES.HOTEL_NOTIFICATION_PAGE_CHANGE:
      return {
        ...state,
        page1: action.payload
      }
    case ACTION_TYPES.HOTEL_NOTIFICATION_LIMIT_CHANGE:
      return {
        ...state,
        limit1: action.payload
      }
    case ACTION_TYPES.FETCH_AMENITY_LOOKUP_SUCCESS:
      return {
        ...state,
        amenities: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_AMENITY_LOOKUP_ERROR:
      return {
        ...state,
        amenities: { Amenities: [] },
      }
    case ACTION_TYPES.FETCH_HOTEL_INFO_SUCCESS:
      return {
        ...state,
        hotelInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_HOTEL_INFO_ERROR:
      return {
        ...state,
        hotelInfo: {},
      }
    case ACTION_TYPES.FETCH_NOTIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        notificationDetail: action.payload.data,
        message1: action.payload.message,
        error1: null
      }
    case ACTION_TYPES.FETCH_NOTIFICATION_DETAILS_ERROR:
      return {
        ...state,
        notificationDetail: {},
      }
    default:
      return { ...state }
  }

}