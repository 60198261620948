//LOGIN Actions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

//CHANGE PASSWORD Actions
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

//Profile Get Actions
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

//AUTHENTICATE USER
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_FAILED = "AUTHENTICATE_FAILED";

//LOGOUT Actions
export const LOGOUT = "LOGOUT"

//get app data settings 
export const GET_APP_DATA_SUCCESS = "GET_APP_DATA_SUCCESS";
export const GET_APP_DATA_ERROR = "GET_APP_DATA_ERROR";
//data change
export const DATACHANGE = "DATACHANGE"
//get t&c
export const GET_TC_SUCCESS = "GET_TC_SUCCESS";
export const GET_TC_ERROR = "GET_TC_ERROR";
