
//FETCH FLAGGED BUSINESS Actions
export const FETCH_FLAGGED_BUSINESS_LIST_SUCCESS = "FETCH_FLAGGED_BUSINESS_LIST_SUCCESS";
export const FETCH_FLAGGED_BUSINESS_LIST_ERROR = "FETCH_FLAGGED_BUSINESS_LIST_ERROR";

//PAGE_CHANGE Actions
export const FLAGGED_BUSINESS_PAGE_CHANGE = "FLAGGED_BUSINESS_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const FLAGGED_BUSINESS_LIMIT_CHANGE = "FLAGGED_BUSINESS_LIMIT_CHANGE"

//FETCH FLAGGED DEAL
export const FETCH_FLAGGED_DEAL_LIST_SUCCESS = "FETCH_FLAGGED_DEAL_LIST_SUCCESS";
export const FETCH_FLAGGED_DEAL_LIST_ERROR = "FETCH_FLAGGED_DEAL_LIST_ERROR";

//PAGE_CHANGE Actions
export const FLAGGED_DEAL_PAGE_CHANGE = "FLAGGED_DEAL_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const FLAGGED_DEAL_LIMIT_CHANGE = "FLAGGED_DEAL_LIMIT_CHANGE"

