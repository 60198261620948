import * as ACTION_TYPES from './Types';

const initialState = {
  isLoggedIn: false,
  userData: {},
  profile: {},
  appDataSettings: {},
  tc: {},
  message: '',
  error: null
}

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_SUCCESS:
      localStorage.setItem('authTokenFGD', JSON.stringify(action.payload.data.accessToken));
      localStorage.setItem('userFGD', JSON.stringify(action.payload.data));
      return {
        ...state,
        userData: action.payload.data,
        message: action.payload.message,
        isLoggedIn: true,
        error: null
      }
    case ACTION_TYPES.LOGIN_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data
      }
    case ACTION_TYPES.AUTHENTICATE_USER:
      return {
        ...state,
        isLoggedIn: true,
        userData: action.payload,
        error: null
      }
    case ACTION_TYPES.GET_APP_DATA_SUCCESS:
      return {
        ...state,
        appDataSettings: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_TC_SUCCESS:
      return {
        ...state,
        tc: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_APP_DATA_ERROR:
      return {
        ...state,
        appDataSettings: {},
        error: null
      }
    case ACTION_TYPES.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_PROFILE_ERROR:
      return {
        ...state,
        profile: {},
        error: null
      }
    case ACTION_TYPES.GET_TC_ERROR:
      return {
        ...state,
        tc: {},
        message: action.payload.data.message,
        error: action.payload.data
      }
    case ACTION_TYPES.LOGOUT:
    case ACTION_TYPES.AUTHENTICATE_FAILED:
      localStorage.removeItem("authTokenFGD");
      localStorage.removeItem("userFGD");
      return {
        ...state,
        isLoggedIn: false,
        message: '',
        userData: {}
      }
    case ACTION_TYPES.DATACHANGE:
      return {
        ...state,
        userData: action.payload
      }
    default:
      return state;
  }
}
