//Get amenity list
export const AMENITYSUPER_LIST_SUCCESS = "AMENITYSUPER_LIST_SUCCESS";
export const AMENITYSUPER_LIST_ERROR = "AMENITYSUPER_LIST_ERROR";
//PAGE_CHANGE Actions
export const AMENITYSUPER_PAGE_CHANGE = "AMENITYSUPER_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const AMENITYSUPER_LIMIT_CHANGE = "AMENITYSUPER_LIMIT_CHANGE"

//Amenity info
export const AMENITYSUPER_INFO_SUCCESS = "AMENITYSUPER_INFO_SUCCESS";
export const AMENITYSUPER_INFO_ERROR = "AMENITYSUPER_INFO_ERROR";

//amenity info
export const FETCH_AMENITYSUPER_INFO_SUCCESS = "FETCH_AMENITYSUPER_INFO_SUCCESS";
export const FETCH_AMENITYSUPER_INFO_ERROR = "FETCH_AMENITYSUPER_INFO_ERROR";

//DELETE AMENITY Action
export const DELETE_AMENITYSUPER_SUCCESS = "DELETE_AMENITYSUPER_SUCCESS";
export const DELETE_AMENITYSUPER_ERROR = "DELETE_AMENITYSUPER_ERROR";
