import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form"; // redux-form reducer
import { reducer as toastrReducer } from "react-redux-toastr";
import { changeState } from "./common/Reducer";
import { loginReducer } from "./login/Reducer";
import { userReducer } from "./user/Reducer";
import { cmsReducer } from "./CMS/Reducer";
import { notificationReducer } from "./notifications/Reducer";
import { businessReducer } from "./business/Reducer";
import { catagoryReducer } from "./catagory/Reducer";
import { dealReducer } from "./deals/Reducer";
import { waitingTimeReducer } from "./waiting-time/Reducer";
import { flaggedReducer } from "./flagged/Reducer";
import { buisnessSubscriptonReducer } from "./ba-subscription/Reducer";
import { businessInfoReducer } from "./business-info/Reducer";
import { adReducer } from "./Ad/Reducer";
import { saHotelReducer } from "./sa-hotel/Reducer";
import { haHotelReducer } from "./ha-hotel/Reducer";
import { saAmenityReducer } from "./Amenity/Reducer";
import { saRestaurantReducer } from "./sa-restaurant/Reducer";
import { daysLogReducer } from "./30DaysLog/Reducer";

export const rootReducer = combineReducers({
  login: loginReducer,
  user: userReducer,
  business: businessReducer,
  changeState: changeState,
  cms: cmsReducer,
  catagory: catagoryReducer,
  notification: notificationReducer,
  wTime: waitingTimeReducer,
  flagged: flaggedReducer,
  ad: adReducer,
  saHotel: saHotelReducer,
  saamenity: saAmenityReducer,
  saRestaurant: saRestaurantReducer,
  haHotel: haHotelReducer,

  businessInfo: businessInfoReducer,
  bSubscription: buisnessSubscriptonReducer,
  deal: dealReducer,
  toastr: toastrReducer,
  form: formReducer,
  dayslog: daysLogReducer,
});
