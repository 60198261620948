import * as ACTION_TYPES from './Types';

const initialState = {
  message: '',
  error: null,
  stateList: { States: [] },
  adList: { recordsTotal: 0, data: [] },
  adInfo: {},
  page: 1,
  length: 10
}

export const adReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.AD_LIST_SUCCESS:
      return {
        ...state,
        adList: action.payload.data,
        message: action.payload.message,
        error: null
      }

    case ACTION_TYPES.FETCH_AD_INFO_SUCCESS:
      return {
        ...state,
        adInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.STATE_LOOKUP_LIST_SUCCESS:
      return {
        ...state,
        stateList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.STATE_LOOKUP_LIST_ERROR:
      return {
        ...state,
        stateList: { States: [] },
      }
    case ACTION_TYPES.AD_LIST_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload
      }
    case ACTION_TYPES.AD_LIST_LIMIT_CHANGE:
      return {
        ...state,
        length: action.payload
      }

    default:
      return { ...state }
  }

}