import * as ACTION_TYPES from './Types';

const initialState = {
  message: '',
  error: null,
  restaurantList: {data: [], recordsTotal: 0},
  page: 1,
  limit: 10,
}

export const saRestaurantReducer = (state = initialState, action) => {

  switch (action.type) {
    case ACTION_TYPES.FETCH_RESTAURANT_LIST_SUCCESS:
      return {
        ...state,
        restaurantList: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.FETCH_RESTAURANT_LIST_ERROR:
      return {
        ...state,
        restaurantList: { data: [], recordsTotal: 0 },
      }

    case ACTION_TYPES.RESTAURANT_MANAGEMENT_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload
      }
    case ACTION_TYPES.RESTAURANT_MANAGEMENT_LIMIT_CHANGE:
      return {
        ...state,
        limit: action.payload
      }
    default:
      return { ...state }
  }

}